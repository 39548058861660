import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './styles/Homeworks.css'; 

const MarkerHomeworks = () => {
  const [homeworks, setHomeworks] = useState([]);
  const [error, setError] = useState('');
  const course = localStorage.getItem('course');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchHomeworks = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BE_URL}/classroom/teacher/${course}/homeworks`);
        const sortedHomeworks = response.data.sort((a, b) => new Date(b.dueDate) - new Date(a.dueDate));
        setHomeworks(sortedHomeworks);
      } catch (err) {
        console.error('Error fetching homeworks:', err);
        setError('Failed to load homeworks.');
      }
    };

    if (course) {
      fetchHomeworks();
    } else {
      setError('Course not found in localStorage');
    }
  }, [course]);

  const handleShowHomework = (homeworkTitle) => {
    localStorage.setItem('homeworkTitle', homeworkTitle)
    navigate(`/marker/${homeworkTitle}`);  //stufents.js
  }
  
  return (
    <div className="marker-homework-container">
      <h2 className="marker-course-heading">Homeworks in {course.replace(/_/g,' ')}</h2>
      {error && <p className="marker-error-message">{error}</p>}
      <table className="marker-homework-table">
        <thead>
          <tr>
            <th>Homework Title</th>
            <th>Due Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {homeworks.map((homework, index) => (
            <tr key={index}>
              <td>{homework.homeworkTitle.replace(/_/g,' ')}</td>
              <td>{new Date(homework.dueDate).toLocaleDateString()}</td>
              <td>
                <button 
                  className="marker-show-homework-btn" 
                  onClick={() => handleShowHomework(homework.homeworkTitle)}>
                  Show Homework
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MarkerHomeworks;