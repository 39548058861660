import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/navbar';
import SectionOne from './components/section_one';
import Classescard from './components/classes_card';
import RevisionSet from './components/revision_set';
import Testimonials from './components/testimonials';
import MockExamAnnouncement from './components/Announcement';
import Footer from './components/Footer';
import BiologyPage from './subjects/biology';
import ChemistryPage from './subjects/chemistry';
import PhysicsPage from './subjects/physics';
import MathPage from './subjects/math';
import AccessExamPaperMain from './access_exam_papers_folder/access_exam_paper_main';
import PhysicsQP from './access_exam_papers_folder/physics_qp';
import ContactUs from './views/contactUs';
import AboutUs from './views/aboutUs'; 
import ReportBug from './components/ReportBug';
import QuizSet from './access_exam_papers_folder/quiz_set';
import LoginComponent from './components/login';
import Register from './components/Register';
import Profile from './components/Profile';
import EditProfile from './components/EditProfile';
import QuestionForm from './components/PostHomework';
import GuideForm from './components/PostGuide';
import PostQuizAns from './components/PostQuizAns';
import EmailVerification from './components/EmailVerification';
import Courses from './components/classroom/Courses';
import Homeworks from './components/classroom/Homeworks';
import SubmitHomework from './components/classroom/SubmitHomework';

import Dashboard from './components/Dashboard/Dashboard';
import StudentList from './components/Dashboard/StudentList';
import Sidebar from './components/Dashboard/Sidebar';
import AdminClasses from './components/Dashboard/classes';


import CourseRegister from './components/classroom/CourseRegister';
import CreateCourseUrlForm from './components/classroom/CreateCourseUrlForm';
import Classes from './components/classroom/Classes';
import GenSstHomework from './components/classroom/GenSstHomework';
import MarkerCourses from './components/classroom/marker/courses';
import MarkerHomeworks from './components/classroom/marker/Homeworks';


import './index.css'; 
import MarkerStudents from './components/classroom/marker/Students';
import MarkerGrade from './components/classroom/marker/Grade';
import VPF from './components/classroom/ViewFile';


const getToken = () => localStorage.getItem('token');


function ProtectedRoute({ element, ...rest }) {
  const token = getToken();
  return token ? element : <Navigate to="/login" />;
}


function AdminDashboardLayout() {
  const [selected, setSelected] = React.useState('dashboard');

  return (
    <div className="flex h-screen">
      <Sidebar selected={selected} setSelected={setSelected} />
      <div className="flex-grow">
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/students" element={<StudentList />} />
          <Route path="/classes" element={<AdminClasses/>}/>
          <Route path="*" element={<Navigate to="/admin-dashboard/dashboard" />} />
        </Routes>
      </div>
    </div>
  );
}

function App() {
//   // Initialize GA and GTM when the app loads
//   useEffect(() => {
//     initGA(); // Initialize Google Analytics 4
//     initializeGTM(); // Initialize Google Tag Manager
//   }, []);

//   // Track page views
//   usePageTracking();

  return (
    <div className="App">
      <BrowserRouter>
        {/* Google Tag Manager NoScript Component
        <GoogleTagManager /> */}
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/register" element={<Register />} />
          <Route path="/subjects/biology" element={<BiologyPage />} />
          <Route path="/subjects/chemistry" element={<ChemistryPage />} />
          <Route path="/subjects/physics" element={<PhysicsPage />} />
          <Route path="/subjects/math" element={<MathPage />} />
          <Route path="/access_exam_paper_main" element={<AccessExamPaperMain />} />
          <Route path="/access_exam_paper_folder/physics_qp" element={<PhysicsQP />} />
          <Route path="/access_exam_paper_folder/quiz_set" element={<QuizSet />} />
          <Route path="/views/contactUs" element={<ContactUs />} />
          <Route path="/views/aboutUs" element={<AboutUs />} />
          <Route path="/components/ReportBug" element={<ReportBug />} />
          <Route path="/login" element={<LoginComponent />} />
          <Route path="/verify-email" element={<EmailVerification />} />

          <Route path="/classes" element ={<Classes/>} />
          <Route path="/sst-class" element ={<Courses/>} />
          <Route path="/sstclass/:course" element={<Homeworks />} />
          <Route path="/sstsubmithomework" element={<SubmitHomework/>} />
          <Route path="/sst-gen-homework" element={<GenSstHomework/>} />
          <Route path="/viewFile" element={<VPF/>} />

          <Route path="/marker/courses" element={<MarkerCourses/>}/>
          <Route path="/marker/homeworks" element={<MarkerHomeworks/>}/>
          <Route path="/marker/:homework" element={<MarkerStudents/>}/>
          <Route path="/marker/grade" element={<MarkerGrade/>}/>


          <Route path="/profile" element={<Profile />} />
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/post-homework" element={<QuestionForm />} />
          <Route path="/post-guide" element={<GuideForm />} />
          <Route path="/admin-dashboard/*" element={<AdminDashboardLayout />} />
          <Route path="/post-ans" element={<PostQuizAns />} />
          <Route path="/register-course" element={<CourseRegister />} />
          <Route path="/create-course" element={<CreateCourseUrlForm />} />
          
        </Routes>
      </BrowserRouter>
    </div>
  );
}

function HomePage() {
  return (
    <>
      <SectionOne />
      <RevisionSet />
      <MockExamAnnouncement />
      <Classescard />
      <Testimonials />
      <Footer />
    </>
  );
}

export default App;
