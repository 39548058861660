import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const EmailVerification = () => {
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = new URLSearchParams(location.search).get('token');
    // sessionStorage.setItem('validationToken',token);
    console.log(token);
  
    if (token) {
      axios.get(`${process.env.REACT_APP_BE_URL}/api/auth/verify-email?token=${token}`)
        .then(response => {
          setMessage('Email verified successfully! You can now log in and complete Your Profile');
          sessionStorage.setItem('validToken', token);
          navigate('/edit-profile');
        })
        .catch(error => {
          setMessage('Verification failed. The link may have expired or is invalid.');
        });
    } else {
      setMessage('No token found, Please verify your email');
      setTimeout(() => {
        navigate('/edit-profile')
      }, 6000);
    }
  }, [location.search, navigate]);
  
  return (
    <div className="verification-container">
      <h2>Email Verification</h2>
      <p>{message}</p>
    </div>
  );
};

export default EmailVerification;
