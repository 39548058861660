import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../redux/actions/authActions';
import '../styles/navbar.css';
import logoImage from '../images/Logo.png';
import { FaBars, FaUserCircle } from 'react-icons/fa';


const useAuth = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const userType = useSelector((state) => state.auth.userType);
  return { isAuthenticated, userType };
};

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  // const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  // const userType = useSelector(state => state.auth.userType);
  // const userType = localStorage.getItem('userType', userType);
  const { isAuthenticated, userType } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
  }, []);

  const toggleMenu = useCallback(() => {
    setMenuOpen((prev) => !prev);
  }, []);

  const toggleUserMenu = useCallback(() => {
    setUserMenuOpen((prev) => !prev);
  }, []);

  const handleLogout = useCallback(() => {
    dispatch(logoutUser());
    navigate('/');
  }, [dispatch, navigate]);

  return (
    <div className={menuOpen ? 'navbar open' : 'navbar'}>
      <Link to="/" className="logo-container">
        <img src={logoImage} className="logoImg" alt="Logo" />
        <p className="logo-text">MrSivaScience Tutoring</p>
      </Link>
      <ul className={menuOpen ? 'nav-links open' : 'nav-links'}>
        <div className="authButtons">
          {isAuthenticated ? (
            <>
              <div className="user-menu">
                <FaUserCircle className="user-icon" onClick={toggleUserMenu} />
                {userMenuOpen && (
                  <div className="user-menu-options">
                    <button onClick={() => navigate('/profile')}>Profile</button>
                    <button onClick={handleLogout}>Logout</button>
                  </div>
                )}
              </div>
            </>
          ) : (
            <a href="/login">Login</a>
          )}
          <a href="/views/contactUs">Contact Us</a>
          <a href="/views/aboutUs">About Us</a>
          {userType === 'admin' && (
            <>
              <a href="/marker/courses">Marker</a>
              <a href="/classes">SST-classes</a>
              <a href="/admin-dashboard">Dashboard</a>
            </>
          )}
          {userType === 'marker' && (
            <>
              <a href="/marker/courses">Marker</a>
            </>
          )}
        </div>
      </ul>
      <div className="hamburger-icon" onClick={toggleMenu}>
        <FaBars />
      </div>
    </div>
  );
};

export default Navbar;
