import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import "./ContactUs.css";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      to_name: "Recipient", // Replace 'Recipient' with the actual recipient's name
      from_name: formData.name,
      phone_no: formData.phone,
      message: formData.message,
    };

    const userID = "JFwNqMHBDn5uUjFLo";

    emailjs
      .send("service_ygvcsd4", "template_xzowdih", templateParams, userID)
      .then(
        (response) => {
          console.log("Email sent successfully!", response);
          setIsSubmitted(true);
        },
        (error) => {
          console.error("Error sending email:", error);
          // Handle error, like showing an error message to the user
        }
      );

    setFormData({
      name: "",
      email: "",
      phone: "",
      message: "",
    });
  };

  return (
    <div className="contact-us-container">
      <h2>Contact Us</h2>

      <div className="footer_contact_us">
        {/* <p>Contact us now to book a place:</p> */}

        <p className="call-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            margin="10px"
            viewBox="0 0 512 512"
            id="call_icon"
          >
            <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
          </svg>
          {/* Telephone number:{" "} */}

          <a
            href="tel:+447572363039"
            style={{ textDecoration: "none", color: "black" }}
          >
            :+44 7572 363039
          </a>
          
        </p>





        <p className="call-2">
          {/* Email:{" "} */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20px"
            width="20px"
            margin="10px"
            viewBox="0 0 128 96"
            id="email"
          >
            <g>
              <path d="M0 11.283V8a8 8 0 0 1 8-8h112a8 8 0 0 1 8 8v3.283l-64 40zm66.12 48.11a4.004 4.004 0 0 1-4.24 0L0 20.717V88a8 8 0 0 0 8 8h112a8 8 0 0 0 8-8V20.717z"></path>
            </g>
          </svg>

          <a
            href="mailto:niru@mrsivasciencetutoring.co.uk"
            style={{ textDecoration: "none", color: "black" }}
          >
            :niru@mrsivasciencetutoring.co.uk
          </a>
        </p>



        
      </div>

      {isSubmitted ? (
        <div className="confirmation-message">
          <p>Thank you for your message! We'll get back to you soon.</p>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="contact-form">
          <div className="form-group">
            <label htmlFor="name" className="label">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="input"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email" className="label">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="input"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone" className="label">
              Phone Number
            </label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="input"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message" className="label">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="input textarea"
              required
            />
          </div>
          <button type="submit" className="submit-button">
            Send
          </button>
        </form>
      )}
    </div>
  );
};

export default ContactUs;