import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import axios from "axios";
import { Sidebar } from "react-pro-sidebar";
import "./quiz_set.css";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import QuizSetSolution from './quiz_set_solution';

const QuizSet = () => {
  const [questions, setQuestions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [subjectFilter, setSubjectFilter] = useState("");
  const [levelFilter, setLevelFilter] = useState("");
  const [examinationFilter, setExaminationFilter] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [categories, setCategories] = useState([]);
  const [levels, setLevels] = useState([]);
  const [examinations, setExaminations] = useState([]);
  const sidebarRef = useRef(null);
  const [userAnswers, setUserAnswers] = useState({});
  const [randomizedQuestions, setRandomizedQuestions] = useState([]);
  const [results, setResults] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [score, setScore] = useState(0);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  // const isVerified = useSelector(state => state.auth.isVerified);
  const isVerified = localStorage.getItem('isVerified') === 'true';
  const [ansIndex,setAnsIndex] = useState(0);
  const [solution,setSolution] = useState(null);
  const navigate = useNavigate();
  // console.log(isVerified);

  const handleCloseSolution = () => {
    setSolution(null); 
  };

  const avail = (isAuthenticated, loginReq) => {  //p->q boolean operation
    if (loginReq) {
      return isAuthenticated;
    }
    return true;
  }; 

  function checkLoginRequirement(isAuthenticated,randomizedQuestions) {
    const isAnyLoginRequired = randomizedQuestions.some(question => !avail(isAuthenticated,question.login_required));
    // console.log(isAnyLoginRequired);
    return isAnyLoginRequired; // Optional: return the result if needed
  }

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_QUIZ_BE_URL}/api/questions`);
        // console.log("API Response:", response.data);  // Log response data
        setQuestions(response.data);
        updateCategories(response.data);
        updateLevels(response.data);
        updateSubjects(response.data);
        updateExaminations(response.data);
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };
    fetchQuestions();
    resetQuizState();
    setSolution(null);
  }, []);

  const updateCategories = (data) => {
    const filteredCategories = [...new Set(data.map((q) => q.category))];
    setCategories(filteredCategories);
  };

  const updateLevels = (data) => {
    const filteredLevels = [...new Set(data.map((q) => q.level))];
    setLevels(filteredLevels);
  };

  const updateSubjects = (data) => {
    const filteredSubjects = [...new Set(data.map((q) => q.subject))];
    setSubjects(filteredSubjects);
  };

  const updateExaminations = (data) => {
    const filteredExaminations = [...new Set(data.map((q) => q.examination))];
    setExaminations(filteredExaminations);
  };

  const randomizeQuestions = () => {
    resetQuizState();
    const filteredQuestions = questions.filter(
      (q) =>
        (categoryFilter.length === 0 || categoryFilter.includes(q.category)) &&
        (subjectFilter === "" || q.subject === subjectFilter) &&
        (levelFilter === "" || q.level === levelFilter) &&
        (examinationFilter === "" || q.examination === examinationFilter)
    );
    const randomized = filteredQuestions.sort(() => 0.5 - Math.random()).slice(0, 10);
    setRandomizedQuestions(randomized);
  };

  const resetQuizState = () => {
    setUserAnswers({});
    setScore(0);
    setResults([]);
    setIsSubmitted(false);
    setSolution(null);
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e) => {
    const sidebarWidth = e.clientX;
    sidebarRef.current.style.width = `${sidebarWidth}px`;
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const handleAnswerSelect = (questionIndex, answer) => {
    setUserAnswers((prevAnswers) => {
      if (prevAnswers[questionIndex] === answer) {
        const { [questionIndex]: _, ...rest } = prevAnswers;
        return rest;
      } else {
        return {
          ...prevAnswers,
          [questionIndex]: answer,
        };
      }
    });
  };

  const handleCategoryChange = (selectedOptions) => {
    setCategoryFilter(selectedOptions ? selectedOptions.map(option => option.value) : []);
  };

  const handleOpenReportBug = () => {
    window.open("../components/ReportBug", "_blank");
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_QUIZ_BE_URL}/api/submit-answers`,
        { answers: userAnswers }
      );

      const markedQuestions = randomizedQuestions.map((question, index) => {
        return {
          ...question,
          userAnswer: userAnswers[index],
          isCorrect: userAnswers[index] === question.correctAns,
        };
      });

      const correctCount = markedQuestions.filter((q) => q.isCorrect).length;
      const totalQuestions = markedQuestions.length;
      const calculatedScore = (correctCount / totalQuestions) * 100;
      setScore(calculatedScore);
      setResults(response.data.results);
      setIsSubmitted(true);
    } catch (error) {
      console.error("Error submitting answers:", error);
    }
  };

  const showSolution = (link,index) => {
    setAnsIndex(index);
    setSolution(link);
    // console.log(link);
  }; 

  // Determine if any question requires login
  const isAnyLoginRequired = randomizedQuestions.some(question => question.login_required);

  return (
    <div className="physics-qp-container">
      <div className="main-content">
        <div className="tab-navigation">
          <button onClick={handleOpenReportBug} className="report_bug_button">
            Report a Bug
          </button>
          <div className="filter-container">
            <select
              value={levelFilter}
              onChange={(e) => setLevelFilter(e.target.value)}
            >
              <option value="">All Exam Boards</option>
              {levels.map((level) => (
                <option key={level} value={level}>
                  {level}
                </option>
              ))}
            </select>
            <select
              value={examinationFilter}
              onChange={(e) => setExaminationFilter(e.target.value)}
            >
              <option value="">All Examination Levels</option>
              {examinations.map((examination) => (
                <option key={examination} value={examination}>
                  {examination}
                </option>
              ))}
            </select>

            <select
              value={subjectFilter}
              onChange={(e) => setSubjectFilter(e.target.value)}
            >
              <option value="">All Subjects</option>
              {subjects.map((subject) => (
                <option key={subject} value={subject}>
                  {subject}
                </option>
              ))}
            </select>

            <Select
              isMulti
              name="categories"
              options={categories.map(category => ({ value: category, label: category }))}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleCategoryChange}
            />

            <button
              className="download-selection-button"
              onClick={randomizeQuestions}
            >
              Generate your Quiz
            </button>
          </div>
        </div>

        <div className="quiz-content">
          <div className = "quiz-solution-container">
            <div
              className={`questions-quiz-content ${solution ? '' : 'questions-quiz-content-no-sol'}`}
              style={{ justifyContent: 'center', alignItems: 'center' }}
            >
              {randomizedQuestions.length === 0 ? (
                <div className="instructions">
                  <p>Please select filters from the dropdowns and click "Generate your Quiz" to start.</p>
                </div>
              ) : (
                randomizedQuestions.map((question, index) => {
                  // console.log("Question:", question); 
                  // console.log(`ans: ${avail(isAuthenticated, question.login_required)}`);
                  // console.log(isVerified);
                  const isAccessible = avail(isAuthenticated, question.login_required);
                  const isVerifiedAndAccessible = avail(isVerified, question.login_required);
                  return isAccessible ? 
                  isVerifiedAndAccessible ?
                  ( <div key={question._id} className="question-block">
                      <h3 className="h3-head">Question {index + 1}</h3>
                      <img
                        src={question.imgLink}
                        alt={`Quiz ${index + 1}`}
                      />
                      <div className="options">
                        {["A", "B", "C", "D"].map((option) => {
                          const isCorrect = isSubmitted && question.correctAns === option;
                          const isSelected = userAnswers[index] === option;
                          const optionClass = isSelected
                            ? isCorrect
                              ? "option selected correct"
                              : isSubmitted
                                ? "option selected incorrect"
                                : "option selected"
                            : isCorrect
                              ? "option correct"
                              : "option";
                          return (
                            <div
                              key={option}
                              className={optionClass}
                              onClick={() => !isSubmitted && handleAnswerSelect(index, option)}
                            >
                              {option}
                            </div>
                          );
                        })}
                      </div>
                      {isSubmitted && (
                        <div className="correct-answer">
                          <h3>Correct Answer: {question.correctAns}</h3>
                          { question.ansLink && <button onClick={()=>showSolution(question.ansLink,index)}>View Solution</button> }
                        </div>
                      )}
                    </div> 
                  ) : 
                  (<div key={question._id} className="question-block">
                    <h3 className="h3-head">Question {index + 1}</h3>
                    <p style={{justifyContent: "center", color:"red", fontStyle:"italic", fontSize:"xsmall"}}>Verify Email & Complete Profile to view</p>
                  </div> ):
                  (<div key={question._id} className="question-block">
                    <h3 className="h3-head">Question {index + 1}</h3>
                    <p style={{justifyContent: "center", color:"red", fontStyle:"italic", fontSize:"xsmall"}}>Login to view</p>
                  </div>)
                })
              )}
            </div>

            {solution && (
              <div className="solution-sidebar">
                <h3 className="h3-head">solution to Question {ansIndex + 1}</h3>
                <QuizSetSolution ansLink={solution} onClose={handleCloseSolution} />
              </div>
            )}
          </div>
        </div>
        <div className="button-container">
              {!isSubmitted && (randomizedQuestions.length > 0 ? (
                checkLoginRequirement(isAuthenticated,randomizedQuestions) ? (
                  <button className="submit-button" onClick={() => navigate('/login')}>Login to view all</button>
                ) : (
                  <button className="submit-button" onClick={handleSubmit}>Submit</button>
                )
              ) : null)}

              {isSubmitted && (<>
                {/* <button className="submit-button" onClick={handleSubmit}>Regenerate</button> */}
                  <div className="score-container">
                    <h2>Your Score: {score}% ({score / 10} out of 10)</h2>
                  </div> 
                </>
              )}
          </div>
      </div>
    </div>
  );
};

export default QuizSet;
