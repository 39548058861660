import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './styles/ViewFile.css'; 

const VPF = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { homework } = location.state || {};

  // Redirect to a different page or show an error if no homework data is available
  if (!homework) {
    return <p>No homework data available.</p>;
  }

  // Handle the close button click
  const handleClose = () => {
    navigate(-1);
  };

  return (
    <div className="vpf-container">
      <h2 className="vpf-heading">{homework.homeworkTitle.replace(/_/g,' ')} file</h2>
      
      <div className="vpf-info">
        <div className="vpf-info-group">
          <label htmlFor="grade" className="vpf-label">Grade:</label>
          <input
            id="grade"
            type="text"
            value={homework.grade || 'Unmarked'}
            disabled
            className="vpf-input"
          />
        </div>
        
        <div className="vpf-info-group">
          <label htmlFor="feedback" className="vpf-label">Feedback:</label>
          <textarea
            id="feedback"
            value={homework.feedback || 'No feedback available'}
            disabled
            className="vpf-textarea"
          />
        </div>
        
        {homework.files && (
          <div className="vpf-file">
            <iframe
              src={homework.files}
              title="Homework File"
              width="100%"
              height="600px"
              frameBorder="0"
              className="vpf-iframe"
            />
          </div>
        )}
      </div>
      
      <button
        onClick={handleClose}
        className="vpf-close-button"
      >
        Close
      </button>
    </div>
  );
};

export default VPF;
