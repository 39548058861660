import React from 'react';

import './dashboard.css'; // Import your Tailwind CSS


function Dashboard() {
  return (
    <div className="flex-grow p-8 ml-6">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        <div className="bg-yellow-400 text-black text-center p-6 rounded-lg">
          <h3 className="text-2xl font-semibold">Total Registered Users</h3>
          <p className="text-2xl mt-4">0</p>
        </div>
        <div className="bg-yellow-400 text-black text-center p-6 rounded-lg">
          <h3 className="text-2xl font-semibold">Total Logged-In Students</h3>
          <p className="text-2xl mt-4">0</p>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
