import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AiOutlinePlus } from 'react-icons/ai';

const CreateCourseUrlForm = () => {
  const [course, setCourse] = useState('');
  const [expiryTime, setExpiryTime] = useState('');
  const [generatedUrl, setGeneratedUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [copied, setCopied] = useState(false);
  const [courseOptions, setCourseOptions] = useState([]);



  const containerStyle = {
    display: 'flex', justifyContent: 'center', alignItems: 'center',  padding: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',height: '75vh', flexDirection: 'column', backgroundColor: '#fff4e0', 
  };
  
  const formStyle = {
    display: 'grid', gridTemplateColumns: '1fr 2fr', backgroundColor: '#ffffff',
    gap: '10px', alignItems: 'center', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  };
  
  const labelStyle = {
    textAlign: 'right', fontWeight: 'bold',
    paddingRight: '10px', color: '#004aad',
  };
  
  const inputStyle = {
    marginBottom: '10px', padding: '8px',  width: '200px',
    border: '1px solid #ccc',  borderRadius: '4px', fontSize: '1rem',
  };
  
  const buttonStyle = {
    margin: '10px 0',  padding: '10px 20px',  backgroundColor: '#ffcc00', fontSize: '1rem',
    color: 'black', border: 'none', borderRadius: '4px',  cursor: 'pointer', transition: 'background-color 0.3s ease',
  };
  
  const copyButtonStyle = {
    marginLeft: '10px',  padding: '5px 10px',  backgroundColor: '#2196F3', transition: 'background-color 0.3s ease',
    color: 'white',  border: 'none',  borderRadius: '4px',  cursor: 'pointer', fontSize: '1rem',
  };  

  const copiedTextStyle = {
    marginLeft: '10px',  color: 'green',
    fontWeight: 'bold',
  };

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BE_URL}/classroom/teacher/all-courses`);
        setCourseOptions(response.data);
      } catch (error) {
        console.error('Error fetching course names:', error);
      }
    };
  
    fetchCourses();
  }, []);
  

  const handleCopy = () => {
    if (generatedUrl) {
      navigator.clipboard.writeText(generatedUrl);
      setCopied(true);
      setTimeout(() => setCopied(false), 4000);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setGeneratedUrl('');

    if (expiryTime <= 0) {
      setError('Expiry time must be a positive number.');
      return;
    }

    const courseName = course.trim().replace(/\s+/g, '_').replace('.',':');
    
    try {
      setLoading(true);

      const response = await axios.post(`${process.env.REACT_APP_BE_URL}/classroom/teacher/create-course-url`, {
        courseName: courseName,
        expiryTime
      });

      setGeneratedUrl(response.data.courseUrl);
      setCourse(''); 
      setExpiryTime('');
    } catch (error) {
      setError('Error generating course URL. Please try again.');
      console.error('Error generating course URL:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddNewCourse = () => {
      const newCourse = prompt("Enter a new Course:");
      if (newCourse) {
        setCourseOptions([...courseOptions, newCourse]);
        setCourse(newCourse);
      }
  };

  return (
    <div style={containerStyle}>
      <form onSubmit={handleSubmit} style={formStyle}>
        <label htmlFor="course" style={labelStyle}>
          Course Name:
        </label>
        <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
          <select id="course" value={course} onChange={(e) => setCourse(e.target.value)} required style={inputStyle}>
            <option value="">Select Course</option>
            {courseOptions.map((courseOption, index) => (
              <option key={index} value={courseOption}>{courseOption.replace(/_/g,' ')}</option>
            ))}
          </select>
          <AiOutlinePlus
            onClick={handleAddNewCourse}
            style={{ cursor: 'pointer', marginLeft: '10px', marginBottom: '10px', fontSize: '20px'}}
            title="Add New Course"
          />
        </div>
    
        <label htmlFor="expiryTime" style={labelStyle}>
          Expiry Time (in hours):
        </label>
        <input
          id="expiryTime"
          type="number"
          value={expiryTime}
          onChange={e => setExpiryTime(e.target.value)}
          required
          placeholder="Enter expiry time"
          style={inputStyle}
        />
    
        <div></div> {/*Empty grid cell to align the button */}
        <button type="submit" disabled={loading} style={buttonStyle}>
          {loading ? 'Generating...' : 'Generate URL'}
        </button>
      </form>
    
      {error && <p style={{ color: 'red' }}>{error}</p>}
    
      {generatedUrl && (
        <div>
          <p>Generated URL:</p>
          <a href={generatedUrl} target="_blank" rel="noopener noreferrer">{generatedUrl}</a>
          <button style={copyButtonStyle} onClick={handleCopy}>
            Copy
          </button>
          {copied && <span style={copiedTextStyle}>Copied!</span>}
        </div>
      )}
    </div>
  );
};

export default CreateCourseUrlForm;
