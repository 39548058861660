import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AdminClasses = () => {
  const [courses, setCourses] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null); 
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BE_URL}/classroom/teacher/all-courses`);
        // console.log(response)
        setCourses(response.data); 
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses();
  }, []);

  const handleCourseClick = async (course) => {
    localStorage.setItem('course', course);  
    setSelectedCourse(course); 
    try {
      const response = await axios.get(`${process.env.REACT_APP_BE_URL}/admin/studentsRegistered`, {
        params: { course }
      });
      setStudents(response.data.sort((a, b) => a.name.localeCompare(b.name)));  
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  };

  return (
    <div className="p-6">
      <div className="flex space-x-8">
        {/* Main Content */}
        <div className="w-1/2">
          <h2 className="text-xl font-semibold mb-4">Classes - {courses.length}</h2>
          <ul className="space-y-2">
            {courses.map((course, index) => (
              <li key={index}>
                <button
                  className="text-left w-full bg-gray-100 hover:bg-gray-200 p-2 rounded"
                  onClick={() => handleCourseClick(course)}
                >
                  {course.replace(/_/g,' ')}
                </button>
              </li>
            ))}
          </ul>
        </div>

        {/* Sidebar to show students */}
        <div className="w-1/2">
          <h3 className="text-lg font-semibold mb-4">
            {selectedCourse ? `Students Registered for ${selectedCourse.replace(/_/g,' ')} : (${students.length})` : 'Select a course to view students'}
          </h3>
          {students.length > 0 ? (
            <ul className="space-y-1">
              {students.map((student, index) => (
                <li key={index} className="bg-gray-100 p-2 rounded">
                  <div>
                    <span className="font-medium">{student.name}</span> - <span className="text-sm text-gray-600">{student.email}</span>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-500">{selectedCourse ? 'No students registered for this course.' : 'No course selected.'}</p>
          )}
        </div>
      </div>
    </div>
  );
};


export default AdminClasses;
