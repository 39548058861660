import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import '../styles/EditProfile.css';
import { loginSuccess } from '../redux/actions/authActions';

const EditProfile = () => {
  const [isVerified, setIsVerified] = useState(false);
  const navigate = useNavigate();
  const dispatch= useDispatch();
  let token = useSelector((state) => state.auth.token);
  if(!token) token=localStorage.getItem('token');
  let sessionToken=sessionStorage.getItem('validToken')
  const [user, setUser] = useState({
    googleAccount: '',
    dateOfBirth: '',
    userType: '',
  });
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [loading,setLoading] = useState(false);

  useEffect(() => {
    let request;
  
    if (sessionToken) {
      request = axios.get(`${process.env.REACT_APP_BE_URL}/api/auth/validation-profile`, {
        params: { token: sessionToken },
      });
    }
    else {
      request = axios.get(`${process.env.REACT_APP_BE_URL}/api/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  
    request
    .then(response => {
      setIsVerified(response.data.isVerified);
      // console.log(isVerified);
      const { name, googleAccount, dateOfBirth, userType } = response.data;
      const nameParts = name ? name.split(" ") : ['', ''];
      setFirstName(nameParts[0] || '');
      setLastName(nameParts[1] || '');
      setUser({
        googleAccount,
        dateOfBirth: dateOfBirth ? format(parseISO(dateOfBirth), 'yyyy-MM-dd') : '',
        userType,
      });
    })
    .catch(error => {
      console.error('There was an error fetching the profile!', error);
    });
  }, [token]);

  const isDateOfBirthEmpty = !user.dateOfBirth;
  const isNameEmpty = !firstName || !lastName;

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'firstName') {
      setFirstName(value);
    } else if (name === 'lastName') {
      setLastName(value);
    } else {
      setUser(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (!firstName || !lastName || !user.dateOfBirth) {
      alert("Please fill all the details");
      return;
    }
    
    // console.log(isVerified)
    if (!isVerified) {
      alert("Please verify your email and try again");
      return;
    }
  
    setLoading(true);
  
    const updatedUser = {
      ...user,
      name: `${firstName} ${lastName}`,
    };
    // console.log(updatedUser);
  
    const requestUrl = sessionToken
      ? `${process.env.REACT_APP_BE_URL}/api/update-profile-vialink`
      : `${process.env.REACT_APP_BE_URL}/api/profile`;
  
    const requestConfig = sessionToken
      ? {}
      : { headers: { Authorization: `Bearer ${token}` } };
  
      axios
      .patch(requestUrl, updatedUser, requestConfig)
      .then((res) => {
        alert('Profile updated successfully!');
        sessionStorage.clear();
        localStorage.setItem("isVerified", true); 
        dispatch(loginSuccess(res.token));
        navigate("/");
      })
      .catch((error) => {
        console.error('There was an error updating the profile!', error);
        alert('There was an error updating the profile. Please try again.');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  

  return (
    <div className="edit-profile-container">
      <h2 className="edit-profile-heading">Edit Profile</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>First Name:</label>
          <input
            type="text"
            name="firstName"
            value={firstName}
            onChange={handleChange}
            placeholder="Enter your first name"
            required
          />
        </div>
        <div className="form-group">
          <label>Last Name:</label>
          <input
            type="text"
            name="lastName"
            value={lastName}
            onChange={handleChange}
            placeholder="Enter your last name"
            required
          />
        </div>
        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            name="googleAccount"
            value={user.googleAccount || ''}
            disabled
          />
        </div>
        <div className="form-group">
          <label>Date of Birth:</label>
          <input
            type="date"
            name="dateOfBirth"
            value={user.dateOfBirth}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>User Type:</label>
          <select
            name="userType"
            value={user.userType}
            onChange={handleChange}
          >
            <option value="student">Student</option>
            <option value="parent">Parent</option>
          </select>
        </div>
        {loading ? <button type="submit" disabled>
          Updating profile
        </button> :
        <button type="submit">
          Update Profile
        </button> }
      </form>
    </div>
  );
};

export default EditProfile;
