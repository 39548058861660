import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";


const CourseRegister = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [expired, setExpired] = useState(false);
  const [loading, setLoading] = useState(true);  

  const { courseName, expiryTimestamp } = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return {
      // courseName: atob(decodeURIComponent(params.get('course'))),
      // expiryTimestamp: parseInt(atob(decodeURIComponent(params.get('expiry'))), 10),

      courseName: params.get('course'),
      expiryTimestamp: parseInt(params.get('expiry'), 10),
    };
  }, [location]);
  

  useEffect(() => {
    const registerCourse = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          navigate(`/login?redirect=${encodeURIComponent(location.pathname + location.search)}`);
          setLoading(false);
          return;
        }

        const decoded = jwtDecode(token);
        console.log(decoded);
        const id= decoded.id;
        const email = decoded.email;
        const name = decoded.name;
        const userType = decoded.userType;
        if (userType !== 'sstClassroom' || userType !== 'admin' ) {
          axios.patch(`${process.env.REACT_APP_BE_URL}/api/update-user-type`, {
            id, userType : 'sstClassroom'
          })
            .then(response => {
              localStorage.setItem('token',response.data.token)
              console.log('User updated successfully:', response.data.token);
            })
            .catch(error => {
              console.error('Error updating user type:', error);
            });
        }


        if (!courseName || !expiryTimestamp) {
          alert('Invalid URL. Please check the link.');
          setLoading(false);
          return;
        }

        if (Date.now() > expiryTimestamp) {
          setExpired(true);
          setLoading(false);
          return;
        }
        
        const response = await axios.post(
            `${process.env.REACT_APP_BE_URL}/classroom/students/register`,
            { email:email, name, courseName },
            { headers: { Authorization: `Bearer ${token}` } }
        );

        if (response.data.message) {
          alert(response.data.message);
        }
        navigate('/sst-class');
      } catch (error) {
        console.error('Error registering for course:', error);
        alert('Failed to register for course.');
      } finally {
        setLoading(false); 
      }
    };

    registerCourse();
  }, [courseName, expiryTimestamp, location, navigate]);

  if (loading) {
    return <h2>Processing...</h2>;
  }

  return (
    <div>
      {expired ? (
        <h2>This URL has expired</h2>
      ) : (
        <h2>Registering for Course...</h2>
      )}
    </div>
  );
};

export default CourseRegister;
