import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './styles/Courses.css'; 

const MarkerCourses = () => {
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BE_URL}/classroom/teacher/courses`); 
        setCourses(response.data);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses();
  }, []);

  const handleCourseClick = (course) => {
    localStorage.setItem('course', course);
    navigate(`/marker/homeworks`);
  };

  return (
    <div className="marker-course-container">
       <h2 className="marker-courses-heading">Classes</h2>
      <div className="marker-course-cards">
        {courses.map((course, index) => (
          <div 
            key={index} 
            className="marker-course-card"
            onClick={() => handleCourseClick(course)}
          >
            <h3 style={{ marginBottom: '10px', fontSize: '18px', fontWeight: 'bold' }}>{course.replace(/_/g,' ')}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MarkerCourses;
