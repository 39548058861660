import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {jwtDecode} from "jwt-decode";
import './styles/courses.css'
import { setCourse } from '../../redux/actions/classActions';
import { useDispatch } from 'react-redux';

const Courses = () => {
    const [courses, setCourses] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (!token) {
            return;
        }

        const decodedToken = jwtDecode(token);
        const email = decodedToken.email;

        const fetchCourses = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_BE_URL}/classroom/students/courses`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setCourses(res.data.courses.sort());  
            } catch (error) {
                console.error('Error fetching courses:', error);
            }
        };

        fetchCourses();
    }, [token]);

    const handleCourseClick = (course) => {
        setSelectedCourse(course);
        dispatch(setCourse(course));
        localStorage.setItem("course", course);
        navigate(`/sstclass/${course}`); 
    };

    return (
        <div className="sst-course-container">
            { !token && <p>Please login & try</p>}
            { token && <><h1 className='sst-courses-heading'>Classes</h1>
            {courses.length === 0 && <p>No courses available</p>}
            <div className="sst-course-cards">
                {courses.map((course, index) => (
                    <div 
                        key={index} 
                        className="sst-course-card" 
                        onClick={() => handleCourseClick(course)}
                    >
                        <h3>{String(course).replace(/_/g, ' ')}</h3>
                    </div>
                ))}
            </div></> }
        </div>
    );
};

export default Courses;
