import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/Footer.css";
import logo from "../images/Logo.png";

const Footer = () => {
  return (
    <div className="footer_container">
      <footer className="footer">
        <div className="footer_content">
          <div className="footer_quicklinks">
            <div className="image_container">
              <img src={logo} alt="Logo" />
            </div>
            <div className="Links_container">
              <h4 style={{ fontSize: "20px", fontWeight: "900",marginBottom:"10px"}}>
                Quick Links:
              </h4>
              <p>
                <a href="/views/aboutUs">About Us</a>
              </p>
              <p>
                <a href="/views/contactUs">Contact Us</a>
              </p>
              <p>
                <a href="/access_exam_paper_folder/physics_qp">
                  Access Exam Questions
                </a>
              </p>
              <p>
                <a href="/access_exam_paper_folder/quiz_set">Access Quiz</a>
              </p>
            </div>
          </div>

          <div className="footer_contacts">
            <p>Contact us now to book a place:</p>
            <p className="call_footer" >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20px"
                height="20px"
                viewBox="0 0 512 512"
                id="call_icon"
              >
                <path
                  fill="white"
                  d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"
                />
              </svg>
              <a
                href="tel:+447572363039"
                style={{ color: "white", textDecoration: "none" }}
              >
                : +44 7572 363039
              </a>
            </p>

            <p className="call_footer"  >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="20"
                height="20"
              >
                <path
                  fill="#ffffff"
                  d="M20.016 3H3.984c-1.102 0-1.969.938-1.969 2.016v13.969c0 1.078.867 2.016 1.969 2.016h16.031c1.078 0 1.969-.938 1.969-2.016V5.016c0-1.078-.891-2.016-1.969-2.016zm0 3l-8.016 5.906-8.016-5.906V5.016l8.016 5.906L20.016 5.016zm0 10.922l-8.016-5.906-8.016 5.906V18.94l8.016-5.906 8.016 5.906v-2.022z"
                />
              </svg>
              <a
                href="mailto:niru@mrsivasciencetutoring.co.uk"
                style={{ color: "white", textDecoration: "none" }}
              >
                :niru@mrsivasciencetutoring.co.uk
              </a>
            </p>
          </div>
        </div>
        <div className="footer_copyright" >&copy;Copyright MrSivaScienceTutoring</div>
      </footer>
    </div>
  );
};

export default Footer;