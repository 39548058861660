export const SET_COURSE = 'SET_COURSE'
export const SET_HOMEWORK = 'SET_HOMEWORK';

export const setCourse = (course) => ({
    type: SET_COURSE,
    payload: course,
});

export const setHomework = (homework) => ({
    type: SET_HOMEWORK,
    payload: homework,
});
