import React, { useState, useEffect } from 'react';
import '../styles/PostHomework.css'; 
import axios from 'axios';
import { uploadImagesToS3 } from './s3';

const QuestionForm = () => {
    const [level, setLevel] = useState('');
    const [subject, setSubject] = useState('');
    const [topic, setTopic] = useState('');
    const [subtopic, setSubtopic] = useState('');
    const [module, setModule] = useState('');
    const [type, setType] = useState('MCQ');
    const [question, setQuestion] = useState('');
    const [options, setOptions] = useState(['']);
    const [images, setImages] = useState(['']);
    const [hints, setHints] = useState(['']);
    const [refs, setRefs] = useState(['']);
    const [answers, setAnswers] = useState(['']);
    const [params,setParams] = useState({});
    const [loading, setLoading] = useState(false);

    const levelOptions = ['Primary', 'Lower Secondary', 'GCSE'];
    const subjectOptions = ['Biology', 'Physics', 'Chemistry'];
    const [topicOptions, setTopicOptions] = useState(['Cell', 'Evolution']);
    const [subtopicOptions, setSubtopicOptions] = useState(['Animal Cells', 'Plant Cells']);
    const [moduleOptions, setModuleOptions] = useState(['Module 1', 'Module 2']);

    const fetchUniqueModules = async (params) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BE_URL}/task/unique_module`, { params });
            return response.data;
        } catch (error) {
            console.error("Error fetching unique modules:", error);
            throw error; 
        }
    };
    
    const fetchUniqueTopics = async (params) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BE_URL}/task/unique_topic`, { params });
            return response.data;
        } catch (error) {
            console.error("Error fetching unique topics:", error);
            throw error; 
        }
    };
    
    const fetchUniqueSubtopics = async (params) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BE_URL}/task/unique_subtopic`, { params });
            return response.data;
        } catch (error) {
            console.error("Error fetching unique subtopics:", error);
            throw error; 
        }
    };
    
    useEffect(() => {
        const updatedParams = {};
        if (subject) updatedParams.subject = subject;
        if (topic) updatedParams.topic = topic;
        if (subtopic) updatedParams.subtopic = subtopic;
        setParams(updatedParams);
    }, [subject, topic, subtopic]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                if (params.subject) {
                    if (params.topic) {
                        if (params.subtopic) {
                            const modules = await fetchUniqueModules(params);
                            setModuleOptions(modules);
                        } else {
                            const subtopics = await fetchUniqueSubtopics(params);
                            setSubtopicOptions(subtopics);
                        }
                    } else {
                        const topics = await fetchUniqueTopics(params);
                        setTopicOptions(topics);
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [params]);
    
    const handleAddNewTopic = () => {
        const newTopic = prompt("Enter a new topic:");
        if (newTopic) {
            setTopicOptions([...topicOptions, newTopic]);
            setTopic(newTopic);
        }
    };

    const handleAddNewSubtopic = () => {
        const newSubtopic = prompt("Enter a new subtopic:");
        if (newSubtopic) {
            setSubtopicOptions([...subtopicOptions, newSubtopic]);
            setSubtopic(newSubtopic);
        }
    };

    const handleAddNewModule = () => {
        const newModule = prompt("Enter a new module:");
        if (newModule) {
            setModuleOptions([...moduleOptions, newModule]);
            setModule(newModule);
        }
    };

    const handleAddField = (setter, state) => {
        setter([...state, '']);
    };

    const handleRemoveField = (setter, state, index) => {
        const newState = [...state];
        newState.splice(index, 1);
        setter(newState);
    };

    const handleChange = (setter, index, value, state) => {
        const newState = [...state];
        newState[index] = value;
        setter(newState);
    };

    const handleImageChange = (index, file) => {
        const newImages = [...images];
        newImages[index] = file;
        setImages(newImages);
    };

    let uploadedUrls = [];

    const handleSubmit = async (event) => {
        setLoading(true); 

        if (!level || !subject || !topic || !subtopic) {
            alert('Please fill in all fields: level, subject, topic, and subtopic');
            setLoading(false);
            return;
        }

        if (!question.trim()) {
            alert('Question cannot be empty.');
            setLoading(false);
            return;
        }
    
        if (answers.length === 0 || answers.some(answer => !answer.trim())) {
            alert('At least one answer is required and should not be empty.');
            setLoading(false);
            return;
        }
        
        uploadedUrls = [];
        event.preventDefault();
        
        if (images.length>0){
            const path = `Tasks/${level}/${subject}/${topic}/${subtopic}/${module}`;

            try {
                uploadedUrls = await uploadImagesToS3(images, path);
                console.log('Uploaded image URLs:', uploadedUrls);
            } catch (error) {
                console.error(error);
            }
        }
    
        const newQuestionData = {
            "level": level,
            "subject": subject,
            "topic": topic,
            "subtopic": subtopic,
            "module": module,
            "type": type,
            "question": question,
            "answer": answers,
            "options": options,
            "image": uploadedUrls,
            "hint": hints,
            "ref": refs,
        };
    
        try {
            const response = await axios.post(`${process.env.REACT_APP_BE_URL}/task`, newQuestionData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            
            alert("Successfully added.")
            console.log('Response Status:', response); // Log the status code


            setType('MCQ'); setImages(['']); setQuestion('');
            setOptions(['']); setHints(['']); setAnswers(['']); setRefs(['']);

   
        } catch (error) {
            console.error('There was an error!', error);
            alert('Failed to create task. Please try again.');
        }
        finally {
            setLoading(false);
        }
    };

    return (
        <form className="questionForm " onSubmit={handleSubmit}>
            <label>
                Level:
                <select  value={level} onChange={(e) => setLevel(e.target.value)} required>
                    <option value="">Select Level</option>
                    {levelOptions.map((level, index) => (
                        <option key={index} value={level}>{level}</option>
                    ))}
                </select>
            </label>

            <label>
                Subject:
                <select  value={subject} onChange={(e) => setSubject(e.target.value)} required>
                    <option value="">Select Subject</option>
                    {subjectOptions.map((subject, index) => (
                        <option key={index} value={subject}>{subject}</option>
                    ))}
                </select>
            </label>

            <label>
                Topic:
                <select  value={topic} onChange={(e) => e.target.value === 'Other' ? handleAddNewTopic() : setTopic(e.target.value)} required>
                    <option value="">Select Topic</option>
                    {topicOptions.map((topic, index) => (
                        <option key={index} value={topic}>{topic}</option>
                    ))}
                    <option value="Other">Other</option>
                </select>
            </label>

            <label>
                Subtopic:
                <select  value={subtopic} onChange={(e) => e.target.value === 'Other' ? handleAddNewSubtopic() : setSubtopic(e.target.value)} required>
                    <option value="">Select Subtopic</option>
                    {subtopicOptions.map((subtopic, index) => (
                        <option key={index} value={subtopic}>{subtopic}</option>
                    ))}
                    <option value="Other">Other</option>
                </select>
            </label>

            <label>
                Module:
                <select value={module} onChange={(e) => e.target.value === 'Other' ? handleAddNewModule() : setModule(e.target.value)} required>
                    <option value="">Select Module</option>
                    {moduleOptions.map((module, index) => (
                        <option key={index} value={module}>{module}</option>
                    ))}
                    <option value="Other">Other</option>
                </select>
            </label>

            <label>
                Select Question Type:
                <select value={type} onChange={(e) => setType(e.target.value)} required>
                    <option value="SingleCorrect">MCQ - Single Correct</option>
                    <option value="FIB">Fill in the Blanks</option>
                    <option value="match_the_following">Match the Following</option>
                    <option value="MCQ">Multiple Select</option>
                    <option value="multimatchquestion">Multi Match Question</option>
                </select>
            </label>

            {(type === 'MCQ' || type === 'SingleCorrect') && (
                <>
                    <label>
                        Question:
                        <input type="text" value={question} onChange={(e) => setQuestion(e.target.value)} required />
                    </label>

                    <label>Options:</label>
                    {options.map((option, index) => (
                        <div key={index} className="option-input">
                            <input
                                type="text"
                                value={option}
                                onChange={(e) => handleChange(setOptions, index, e.target.value, options)}
                                placeholder={`Option ${index + 1}`}
                                required
                            />
                            <button type="button" onClick={() => handleRemoveField(setOptions, options, index)}>
                                Remove
                            </button>
                        </div>
                    ))}
                    <button type="button" onClick={() => handleAddField(setOptions, options)}>
                        Add Option
                    </button>

                    <label>Correct Answers:</label>
                    {answers.map((answer, index) => (
                        <div key={index} className="option-input">
                            <input
                                type="text"
                                value={answer}
                                onChange={(e) => handleChange(setAnswers, index, e.target.value, answers)}
                                placeholder={`Answer ${index + 1}`}
                                required
                            />
                            {type !== 'SingleCorrect' && (
                                <button type="button" onClick={() => handleRemoveField(setAnswers, answers, index)}>
                                    Remove
                                </button>
                            )}
                        </div>
                    ))}
                    {type !== 'SingleCorrect' && (
                        <button type="button" onClick={() => handleAddField(setAnswers, answers)}>
                            Add Answer
                        </button>
                    )}
                </>
            )}

            {(type === 'FIB') && (
                <>
                    <label>
                        Question:
                        <input type="text" value={question} onChange={(e) => setQuestion(e.target.value)} required />
                    </label>

                    <label>Correct Answers:</label>
                    {answers.map((answer, index) => (
                        <div key={index} className="option-input">
                            <input
                                type="text"
                                value={answer}
                                onChange={(e) => handleChange(setAnswers, index, e.target.value, answers)}
                                placeholder={`Answer ${index + 1}`}
                                required
                            />
                            {type !== 'SingleCorrect' && (
                                <button type="button" onClick={() => handleRemoveField(setAnswers, answers, index)}>
                                    Remove
                                </button>
                            )}
                        </div>
                    ))}
                    {type !== 'SingleCorrect' && (
                        <button type="button" onClick={() => handleAddField(setAnswers, answers)}>
                            Add Answer
                        </button>
                    )}
                </>
            )}

            {(type === 'match_the_following' || type === 'multimatchquestion') && (
                <>
                    <label>
                        Question:
                        <input type="text" value={question} onChange={(e) => setQuestion(e.target.value)} required />
                    </label>
                    <label>Match the Following:</label>
                    {options.map((option, index) => (
                        <div key={index} className="option-input">
                            <input
                                type="text"
                                value={option}
                                onChange={(e) => handleChange(setOptions, index, e.target.value, options)}
                                placeholder={`Question Part ${index + 1}`}
                                required
                            />
                            <input
                                type="text"
                                value={answers[index]}
                                onChange={(e) => handleChange(setAnswers, index, e.target.value, answers)}
                                placeholder={`Answer Part ${index + 1}`}
                                required
                            />
                            <button type="button" onClick={() => handleRemoveField(setOptions, options, index)}>
                                Remove
                            </button>
                        </div>
                    ))}
                    <button type="button" onClick={() => handleAddField(setOptions, options)}>
                        Add Pair
                    </button>
                </>
            )}

            <label>Images:</label>
            {images.map((image, index) => (
                <div key={index} className="image-input">
                    <input
                        type="file"
                        onChange={(e) => handleImageChange(index, e.target.files[0])}
                        placeholder={`Image ${index + 1}`}
                    />
                    <button type="button" onClick={() => handleRemoveField(setImages, images, index)}>
                        Remove
                    </button>
                </div>
            ))}
            <button type="button" onClick={() => handleAddField(setImages, images)}>
                Add Image
            </button>

            <label>Hints:</label>
            {hints.map((hint, index) => (
                <div key={index} className="hint-input">
                    <input
                        type="text"
                        value={hint}
                        onChange={(e) => handleChange(setHints, index, e.target.value, hints)}
                        placeholder={`Hint ${index + 1}`}
                    />
                    <button type="button" onClick={() => handleRemoveField(setHints, hints, index)}>
                        Remove
                    </button>
                </div>
            ))}
            <button type="button" onClick={() => handleAddField(setHints, hints)}>
                Add Hint
            </button>

            <label>References:</label>
            {refs.map((ref, index) => (
                <div key={index} className="ref-input">
                    <input
                        type="text"
                        value={ref}
                        onChange={(e) => handleChange(setRefs, index, e.target.value, refs)}
                        placeholder={`Reference ${index + 1}`}
                    />
                    <button type="button" onClick={() => handleRemoveField(setRefs, refs, index)}>
                        Remove
                    </button>
                </div>
            ))}
            <button type="button" onClick={() => handleAddField(setRefs, refs)}>
                Add Reference
            </button>

            <button
                type="submit"
                onClick={handleSubmit}
                disabled={loading}
                className={`${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
                {loading ? 'Submitting...' : 'Submit'}
            </button>


            {/* <div className="flex items-center justify-center h-screen"> <button type="submit">Submit Question</button> </div> */}
        </form>
    );
};

export default QuestionForm;
