import React, { useEffect, useState, useCallback } from "react";
// import Button from "react-bootstrap/Button";
// import Card from "react-bootstrap/Card";
// import physics from "../images/physics.jpg";
// import chemistry from "../images/chemistry.jpg";
// import biology from "../images/biology.jpg";
// import math from "../images/math.jpg";
import { useNavigate } from "react-router-dom"; 
import "../styles/revision_set.css";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";


const RevisionSet = () => {
  // const token = useSelector((state)=> state.auth.token);
  const token = localStorage.getItem('token');
  const navigate = useNavigate(); 
  const [userType, setUserType] = useState('');

  const handleAccessExamQuestions = useCallback(() => {
    navigate("/access_exam_paper_folder/physics_qp");
  }, [navigate]);

  const handleQuiz = useCallback(() => {
    navigate("/access_exam_paper_folder/quiz_set");
  }, [navigate]);

  useEffect(() => {
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setUserType(decoded.userType);
      } catch (error) {
        console.error('Error decoding token:', error);
      }
    }
  }, [token]);


  const handleHomework = useCallback(() => {
    if (token) {
        const decoded = jwtDecode(token);
        // console.log(decoded);
        const userId = decoded.id;
        const url = `${process.env.REACT_APP_NEWWINDOW_URI}?userId=${encodeURIComponent(userId)}`;
        window.open(url, "_blank");
    } else {
        alert("Login Required");
        navigate("/login");
      }
    }, [token, navigate]);

    const handleClassroom = useCallback(() => {
      navigate('/sst-class');
    }, [navigate]);

  return (
    <div className="revision-set_container">
      <div className="access_exam_questions_btn">
        <div className="button_container">
        <button onClick={handleAccessExamQuestions}>
          Access Exam Questions
        </button>

        <button onClick={handleQuiz}>Access Quiz</button>

        <button onClick={handleHomework}>Access Homework</button>
        {(userType === 'sstClassroom' || userType ==='admin' || userType === 'developer' )&&  <button onClick={handleClassroom}>Access Classroom</button> }
        </div>
      </div>
    </div>
  );
};

export default RevisionSet;