import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './styles/Students.css'; 

const MarkerStudents = () => {
  const [students, setStudents] = useState({});
  const [error, setError] = useState('');
  const course = localStorage.getItem('course');
  const homeworkTitle = localStorage.getItem('homeworkTitle');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BE_URL}/classroom/teacher/${course}/${homeworkTitle}`);
        setStudents(response.data.students.students);
        // console.log(response.data.students.students);
        // console.log(students);
        // console.log(Object.keys(students));
      } catch (err) {
        console.error('Error fetching students:', err);
        setError('Failed to load students.');
      }
    };

    if (course && homeworkTitle) {
      fetchStudents();
    } else {
      setError('Course or HomeworkTitle not found in localStorage');
    }
  }, [course, homeworkTitle]);

  const handleStudentClick = (student, email) => {
    console.log(student);
    sessionStorage.setItem('name',student?.name);
    sessionStorage.setItem('comments',student?.comments);
    sessionStorage.setItem('link',student?.link);
    sessionStorage.setItem('lastModified',student?.lastModified);
    sessionStorage.setItem('email',email);
    sessionStorage.setItem('grade',student?.grade);
    sessionStorage.setItem('feedback',student?.feedback);
    sessionStorage.setItem('status',student?.status);
    navigate(`/marker/grade`);
  };

  return (
    <div className="marker-students-container">
      <h2 className="marker-students-heading">All student's {homeworkTitle.replace(/_/g, ' ')} in {course.replace(/_/g,' ')}</h2>
      {error && <p className="marker-error-message">{error}</p>}
      <table className="marker-students-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Comments</th>
            <th>Status</th>
            <th>Latest Submission</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(students).map((email) => {
            const student = students[email];
            return (
              <tr key={email}>
                <td>{student.name}</td>
                <td>{student.comments}</td>
                <td>{student.status}</td>
                <td>{new Date(student.lastModified).toLocaleDateString()}</td>
                <td>
                  <button 
                    className="marker-grade-btn" 
                    onClick={() => handleStudentClick(student, email)}>
                    View Details
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default MarkerStudents;