import { LOGIN_SUCCESS, LOGOUT } from '../actions/authActions';

const initialState = {
  token: localStorage.getItem('token') || null,
  isAuthenticated: !!localStorage.getItem('token'),
  isVerified: !!localStorage.getItem('isVerified'),
  userType: localStorage.getItem('userType'),
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return { ...state, token: action.payload.token, isAuthenticated: true, userType: action.payload.userType };
    case LOGOUT:
      return { ...state, token: null, isAuthenticated: false, isVerified: false, userType: '' };
    default:
      return state;
  }
};


export default authReducer;
