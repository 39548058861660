import React from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/Classes.css'; 

const Classes = () => {
  const navigate = useNavigate();

  // Array of links and their paths
  const links = [
    { name: 'Post SST Homework', path: '/sst-gen-homework' },
    { name: 'Generate class URL', path: '/create-course' },
  ];

  const handleLinkClick = (path) => {
    navigate(path);
  };

  return (
    <div className="sst-classes-container">
      <h2 className="sst-classes-heading">SST Class Links</h2>
      {links.map((link, index) => (
        <div
          key={index}
          className="sst-class-link"
          onClick={() => handleLinkClick(link.path)}
        >
          {link.name.replace('_', ' ')}
        </div>
      ))}
    </div>
  );
};

export default Classes;
