import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { logout } from '../redux/actions/authActions';
import '../styles/profile.css';

const Profile = () => {
  const [profile, setProfile] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let token = useSelector((state) => state.auth.token);

  useEffect(() => {
    const fetchProfile = async () => {
      // console.log(token);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BE_URL}/api/profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = response.data;

        // Convert date format to YYYY-MM-DD if necessary
        const formattedDateOfBirth = data.dateOfBirth ? new Date(data.dateOfBirth).toISOString().split('T')[0] : '';

        setProfile({
          ...data,
          dateOfBirth: formattedDateOfBirth
        });
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };

    fetchProfile();
  }, [token]);

  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
  };

  const handleEditProfile = () => {
    navigate('/edit-profile');
  };

  return (
    <div className="profile-container">
      <h2 className='profile-heading'>Profile</h2>
      <form className="profile-form">
        <div className="form-group">
          <label>First Name:</label>
          <input type="text" value={(profile && profile.name && profile.name.split(" ")[0]) || ''} disabled />
        </div>
        <div className="form-group">
          <label>Last Name:</label>
          <input type="text" value={(profile && profile.name && profile.name.split(" ")[1]) || ''} disabled />
        </div>
        <div className="form-group">
          <label>Email:</label>
          <input type="email" value={profile.googleAccount || ''} disabled />
        </div>
        <div className="form-group">
          <label>Date of Birth:</label>
          <input type="date" value={profile.dateOfBirth || ''} disabled />
        </div>
        <div className="form-group">
          <label>User Type:</label>
          <input type="text" value={profile.userType || ''} disabled />
        </div>
        <div className="form-buttons">
          <button type="button" className="edit-button" onClick={handleEditProfile}>
            Edit Profile
          </button>
          <button type="button" className="logout-button" onClick={handleLogout}>
            Logout
          </button>
        </div>
      </form>
    </div>
  );
};

export default Profile;
