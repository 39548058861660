import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import PDFMerger from "pdf-merger-js/browser";
import "./physics_qp.css";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

const PhysicsQP = () => {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [selectedPdfs, setSelectedPdfs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [subCategoryFilter, setSubCategoryFilter] = useState("");
  const [subjectFilter, setSubjectFilter] = useState("");
  const [activeTab, setActiveTab] = useState("questions");
  const [currentQuestionPdf, setCurrentQuestionPdf] = useState(null);
  const [currentAnswerPdf, setCurrentAnswerPdf] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [error, setError] = useState(null);
  const sidebarRef = useRef(null);
  const [levels, setLevels] = useState([]);
  const [examLevels, setExamLevels] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [levelFilter, setLevelFilter] = useState("");
  const [examLevelFilter, setExamLevelFilter] = useState("");
  const [isContainerOpen, setIsContainerOpen] = useState(true);
  const navigate = useNavigate();

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const isVerified = localStorage.getItem('isVerified') === 'true';
  // console.log(isVerified);

  const avail = (isAuthenticated, loginReq) => {  //p->q boolean operation
    if (loginReq) {
      return isAuthenticated;
    }
    return true;
  }; 

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BE_URL}/api/questions`
        );
        setQuestions(response.data);
        updateCategories(response.data);
        updateSubcategories(response.data);
        updateLevels(response.data);
        updateExamLevels(response.data);
        updateSubjects(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchQuestions();
  }, []);

  useEffect(() => {
    const fetchAnswers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BE_URL}/api/answers`
        );
        setAnswers(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchAnswers();
  }, []);



  
  const updateCategories = (data) => {
    const filteredCategories = [...new Set(data.map((q) => q.category))];
    setCategories(filteredCategories);
  };

  const updateSubcategories = (data) => {
    const filteredSubcategories = [...new Set(data.map((q) => q.subcategory))];
    setSubcategories(filteredSubcategories);
  };

  const updateLevels = (data) => {
    const filteredLevels = [...new Set(data.map((q) => q.level))];
    setLevels(filteredLevels);
  };
  const updateExamLevels = (data) => {
    const filteredExamLevels = [...new Set(data.map((q) => q.examLevel))];
    setExamLevels(filteredExamLevels);
  };

  const updateSubjects = (data) => {
    const filteredSubjects = [...new Set(data.map((q) => q.subject))];
    setSubjects(filteredSubjects);
  };

  useEffect(() => {
    const filteredCategories = questions
      .filter(
        (q) =>
          (q.examLevel === examLevelFilter || examLevelFilter === "") &&
          (q.subject === subjectFilter || subjectFilter === "") &&
          (q.level === levelFilter || levelFilter === "")
      )
      .map((q) => q.category);
    setCategories([...new Set(filteredCategories)]);
  }, [examLevelFilter, subjectFilter, levelFilter, questions]);
  
  useEffect(() => {
    const filteredSubcategories = questions
      .filter(
        (q) =>
          (q.examLevel === examLevelFilter || examLevelFilter === "") &&
          (q.category === categoryFilter || categoryFilter === "") &&
          (q.subject === subjectFilter || subjectFilter === "") &&
          (q.level === levelFilter || levelFilter === "")
      )
      .map((q) => q.subcategory);
    setSubcategories([...new Set(filteredSubcategories)]);
  }, [examLevelFilter, categoryFilter, subjectFilter, levelFilter, questions]);
  
  useEffect(() => {
    const filteredSubjects = questions
      .filter(
        (q) =>
          (q.examLevel === examLevelFilter || examLevelFilter === "") &&
          (q.level === levelFilter || levelFilter === "")
      )
      .map((q) => q.subject);
    setSubjects([...new Set(filteredSubjects)]);
  }, [examLevelFilter, levelFilter, questions]);
  
  useEffect(() => {
    const filteredLevels = questions
      .filter((q) => q.examLevel === examLevelFilter || examLevelFilter === "")
      .map((q) => q.level);
    setLevels([...new Set(filteredLevels)]);
  }, [examLevelFilter, questions]);





  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (tab === "answers" && selectedQuestion) {
      const correspondingAnswer = answers.find(
        (answer) => answer.link === selectedQuestion.link
      );
      if (correspondingAnswer) {
        setCurrentAnswerPdf(correspondingAnswer.pdfLink);
      }
    }
  };




  const handleCheckboxChange = (question) => {
    if (!avail(isVerified, question.login_required) ) {
      alert(
        "Please verify your email sent to your inbox (sometimes spam box). If verified, please complete your profile to use this feature."
      );
      return;
    }
    // console.log(isVerified);
  
    setSelectedPdfs((prev) => {
      const isSelected = prev.some((pdf) => pdf.pdfLink === question.pdfLink);
      return isSelected
        ? prev.filter((pdf) => pdf.pdfLink !== question.pdfLink)
        : [...prev, question];
    });
  };
  

  const handleDownloadSelectedPdfs = async () => {
    const merger = new PDFMerger();
    for (const question of selectedPdfs) {
      await merger.add(question.pdfLink);
      const correspondingAnswer = answers.find(
        (answer) => answer.link === question.link
      );
      if (correspondingAnswer) {
        await merger.add(correspondingAnswer.pdfLink);
      }
    }
    const mergedPdf = await merger.saveAsBlob();
    const url = URL.createObjectURL(mergedPdf);
    const a = document.createElement("a");
    a.href = url;
    a.download = "merged_questions_and_answers.pdf";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const filteredQuestions = questions.filter((question) => {
    let matchesSearch = true;
    let matchesCategory = true;
    let matchesSubCategory = true;
    let matchesSubject = true;
    let matchesLevel = true;
    let matchesExamLevel = true;

    if (searchTerm.trim() !== "") {
      matchesSearch = question.text
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
    }

    if (subjectFilter !== "") {
      matchesSubject = question.subject === subjectFilter;
    }

    if (categoryFilter !== "") {
      matchesCategory = question.category === categoryFilter;
    }

    if (subCategoryFilter !== "") {
      matchesSubCategory = question.subcategory === subCategoryFilter;
    }

    if (levelFilter !== "") {
      matchesLevel = question.level === levelFilter;
    }

    if (examLevelFilter !== "") {
      matchesExamLevel = question.examLevel === examLevelFilter;
    }

    return (
      matchesSearch &&
      matchesSubject &&
      matchesCategory &&
      matchesSubCategory &&
      matchesLevel &&
      matchesExamLevel
    );
  });

  const handleOpenReportBug = () => {
    window.open("../components/ReportBug", "_blank");
  };

  const handleViewPdf = (pdfLink, type) => {
    if (type === "question") {
      setCurrentQuestionPdf(pdfLink);
    } else if (type === "answer") {
      setCurrentAnswerPdf(pdfLink);
    }
  };

  const handleClearSelections = () => {
    setSelectedPdfs([]);
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e) => {
    const sidebarWidth = e.clientX;
    sidebarRef.current.style.width = `${sidebarWidth}px`;
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  // To view pDF
  const handleQuestionSelection = (question) => {
    if (!avail(isVerified, question.login_required) ) {
      alert(
        "Please verify your email sent to your inbox (sometimes spam box). If verified, please complete your profile to use this feature."
      );
      return;
    }
    setSelectedQuestion(question);
    setCurrentQuestionPdf(question.pdfLink);
    setActiveTab("questions");
  };

  return (
    <div className="physics-qp-container">
      <Sidebar className="sidebar" style={{backgroundColor:"#f8f9fa"}}>
        <div className="filter-container_qp">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          <select
            value={levelFilter}
            onChange={(e) => setLevelFilter(e.target.value)}
          >
            <option value="">All Exam Boards</option>
            {levels.map((level) => (
              <option key={level} value={level}>
                {level}
              </option>
            ))}
          </select>

          <select
            value={examLevelFilter}
            onChange={(e) => setExamLevelFilter(e.target.value)}
          >
            <option value="">All Exam Levels</option>
            {examLevels.map((examLevel) => (
              <option key={examLevel} value={examLevel}>
                {examLevel}
              </option>
            ))}
          </select>

          <select
            value={subjectFilter}
            onChange={(e) => setSubjectFilter(e.target.value)}
          >
            <option value="">All Subjects</option>
            {subjects.map((subject) => (
              <option key={subject} value={subject}>
                {subject}
              </option>
            ))}
          </select>

          <select
            value={categoryFilter}
            onChange={(e) => setCategoryFilter(e.target.value)}
          >
            <option value="">All Categories</option>
            {categories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>

          <select
            value={subCategoryFilter}
            onChange={(e) => setSubCategoryFilter(e.target.value)}
          >
            <option value="">All Subcategories</option>
            {subcategories.map((subcategory) => (
              <option key={subcategory} value={subcategory}>
                {subcategory}
              </option>
            ))}
          </select>

          {selectedPdfs.length > 0 && (
            <button
              className="download-selection-button"
              onClick={handleDownloadSelectedPdfs}
            >
              Download Selected PDFs
            </button>
          )}
          {selectedPdfs.length > 0 && (
            <button
              className="clear-selection-button"
              onClick={handleClearSelections}
            >
              Clear All Selections
            </button>
          )}
        </div>
        <Menu>
          {error && <MenuItem>{error}</MenuItem>}
          {filteredQuestions.map((question) => {
            // console.log(question);
            return (
              <MenuItem
                className="menuItem"
                key={question._id}
                style={{ margin: "10px", padding: "15px" }}>
                <div className="checkbox_plus_sidebar_header">
                  {avail(isAuthenticated, question.login_required)? (<input
                    className="checkbox"
                    type="checkbox"
                    checked={selectedPdfs.some((pdf) => pdf.pdfLink === question.pdfLink)}
                    onChange={() => handleCheckboxChange(question)}
                  />): " "}
                  <div className="header_plus_view_pdf_button">
                    <p className="sidebar_header">{question.text}</p>
                    {avail(isAuthenticated, question.login_required) ? (
                      <button className="view_pdf" onClick={() => handleQuestionSelection(question)}>
                        View PDF
                      </button>
                    ) : (
                      <button className="view_pdf" onClick={() => navigate('/login')}>
                        Login to view
                      </button>
                    )}
                  </div>
                </div>
              </MenuItem>
            );
          })}
        </Menu>

      </Sidebar>

      <div
        className={`main-content_qp  ${isContainerOpen ? "container-closed" : ""}`}
      >
        <div className="tab-navigation">
          <button
            className={activeTab === "questions" ? "tab active" : "tab"}
            onClick={() => handleTabChange("questions")}
          >
            Questions
          </button>
          <button
            className={activeTab === "answers" ? "tab active" : "tab"}
            onClick={() => handleTabChange("answers")}
          >
            Answers
          </button>
          <button onClick={handleOpenReportBug} className="report_bug_button">
            Report a Bug
          </button>
        </div>

        {/* IFRAME CODE */}
        <div className="content">
          {activeTab === "questions" && (
            <div className="questions-content">
              {currentQuestionPdf ? (
                <iframe
                  title="Question PDF Viewer"
                  src={currentQuestionPdf}
                  style={{ width: "100%", height: "85vh" }}
                />
              ) : (
                <div className="no-pdf-message" style={{marginLeft:"160px"}}>
                  Please select a question to view its PDF.
                </div>
              )}
            </div>
          )}

          {activeTab === "answers" && (
            <div className="answers-content">
              {currentAnswerPdf ? (
                <iframe
                  title="Answer PDF Viewer"
                  src={currentAnswerPdf}
                  style={{ width: "100%", height: "85vh" }}
                />
              ) : (
                <div className="no-pdf-message">
                  Please select a question to view its corresponding answer PDF.
                </div>
              )}
            </div>
          )}
        </div>

    
       
      </div>

      <div className="selected_sidebar">
        <div>
          <h2>Selected PDF's</h2>
          <div className="">
            {selectedPdfs.length > 0 && isContainerOpen ? (
              <div className="selected-pdfs-list">
                <ul>
                  {" "}
                  {/* Wrap PDF items in a <ul> (unordered list) */}
                  {selectedPdfs.map((selectedPdf, index) => (
                    <li key={index} className="selected_pdf_header">
                      <p style={{ margin: "15px" }}> {selectedPdf.text}</p>{" "}
                      {/* Display bullet point before PDF text */}
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              // <p style={{ textAlign: "center" , marginTop:"15px",fontSize:"20px",}}>Selected PDF's will be shown here</p>
              <p className="Selected_pdf_command">
                Selected PDF's will be shown here
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhysicsQP;